/**
 * A component that renders a form input field.
 * @param {Object} props - The props object.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.type - The type of the input field.
 * @param {string} [props.placeholder=""] - The placeholder text for the input field.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.value - The value of the input field.
 * @param {function} props.onChange - The function to be called when the input field value changes.
 * @returns {JSX.Element} - The JSX element representing the form input field.
 */
import React from "react";
import Form from "react-bootstrap/Form";

function FormInput({
  label,
  type,
  placeholder = "",
  name,
  value,
  onChange,
  accept,
  required = false,
}) {
  return (
    <Form.Group className="mb-3" controlId={name}>
      <Form.Label htmlFor={name}>{name}</Form.Label>
      <Form.Control
        type={type}
        id={label}
        aria-describedby={name}
        onChange={onChange}
        value={value}
        accept={accept}
        placeholder={placeholder}
        required={required}
      />
    </Form.Group>
  );
}

export default FormInput;
