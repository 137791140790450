import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";

import FormInput from "../../components/FormInput";
import NavbarCustom from "../../components/Navbar";
import ListItem from "../../components/ListItem";

const EditProject = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState({
    name: "",
    shortname: "",
    startyear: "",
    endyear: "",
    description: "",
    url: "",
    video: "",
    past: 0,
    photo: "",
    people: [],
    publications: [],
  });

  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);

  //Users
  const [people, setPeople] = useState([]);
  const [searchPeople, setSearchPeople] = useState("");

  const filteredPeople = useMemo(() => {
    const search = people.filter(
      (person) =>
        person.firstname.toLowerCase().includes(searchPeople.toLowerCase()) ||
        person.lastname.toLowerCase().includes(searchPeople.toLowerCase())
    );

    if (!project.people) return search;

    const noDuplicate = search.filter(
      (person) => !project.people.some((item) => item.id === person.id)
    );

    return noDuplicate;
  }, [people, searchPeople, project]);

  //Publications
  const [publications, setPublications] = useState([]);
  const [searchPublications, setSearchPublications] = useState("");

  const filteredPublications = useMemo(() => {
    const search = publications.filter((publication) =>
      publication.title.toLowerCase().includes(searchPublications.toLowerCase())
    );

    if (!project.publications) return search;

    const noDuplicate = search.filter(
      (publication) =>
        !project.publications.some((item) => item.id === publication.id)
    );

    return noDuplicate;
  }, [publications, searchPublications, project]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await fetch(`/api/projects/${projectId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProject(() => ({
            ...data.projectData[0],
            people: data.userData || [],
            publications: data.paperData || [],
          }));
        } else {
          navigate("/admin/projects");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPeople = async () => {
      try {
        const response = await fetch(`/api/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPeople(data);
        } else {
          throw new Error("Failed to get people");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPublications = async () => {
      try {
        const response = await fetch(`/api/publications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPublications(data);
        } else {
          throw new Error("Failed to get publications");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (projectId && projectId !== "new") {
      fetchProject();
    }

    fetchPeople();
    fetchPublications();
  }, [projectId]);

  const deleteProject = async (id) => {
    try {
      const response = await fetch(`/api/projects/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        navigate("/admin/projects");
      } else {
        throw new Error("Failed to delete project");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "photo") setFile(files[0]);

    setProject((prevproject) => ({
      ...prevproject,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("photo", file ? file : project.photo);
      formData.append("name", project.name);
      formData.append("shortname", project.shortname);
      formData.append("startyear", project.startyear);
      formData.append("endyear", project.endyear);
      formData.append("description", project.description);
      formData.append("url", project.url);
      formData.append("video", project.video);
      formData.append("past", project.past ? 1 : 0);
      formData.append("people", JSON.stringify(project.people));
      formData.append("publications", JSON.stringify(project.publications));

      const isProjectId = projectId && projectId !== "new";

      const response = await fetch(
        isProjectId ? `/api/projects/${projectId}` : `/api/projects`,
        {
          method: isProjectId ? "PUT" : "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        navigate("/admin/projects");
      } else {
        throw new Error("Failed to update project");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <header>
        <NavbarCustom isAdmin />
      </header>
      <h1>Edit project</h1>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Container>
          <Row>
            <Col>
              <FormInput
                name="Name"
                label="name"
                value={project.name}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col>
              <FormInput
                name="Short Name"
                label="shortname"
                value={project.shortname}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Start Year"
                label="startyear"
                value={project.startyear}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col>
              <FormInput
                name="End Year (0 means no end year)"
                label="endyear"
                value={project.endyear}
                onChange={handleInputChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Description"
                label="description"
                value={project.description}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <FormInput
                name="URL"
                label="url"
                value={project.url}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                name="Video"
                label="video"
                value={project.video}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              {project.photo ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Form.Label>Image</Form.Label>
                  <div>
                    <img
                      src={`${project.photo}`}
                      alt="project photo"
                      style={{ width: "100px", height: "100px" }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="red"
                      class="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => {
                        setProject((prevproject) => ({
                          ...prevproject,
                          photo: "",
                        }));
                      }}
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                    </svg>
                  </div>
                </div>
              ) : (
                <FormInput
                  name="Photo"
                  type="file"
                  label="photo"
                  accept="image/*"
                  value={project.photo}
                  onChange={handleInputChange}
                  required
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Form.Label>Past Project</Form.Label>
              <InputGroup.Checkbox
                aria-label="Checkbox for past project"
                onChange={(e) => {
                  setProject((prevProject) => ({
                    ...prevProject,
                    past: e.target.checked,
                  }));
                }}
                value={project.past}
                checked={project.past}
              />
            </Col>
          </Row>
          <h2>People</h2>
          <FormInput
            name="Search for a person"
            type="text"
            placeholder="Search for a person"
            label="searchPeople"
            value={searchPeople}
            onChange={(e) => setSearchPeople(e.target.value)}
          />
          {filteredPeople.map((person) => {
            if (searchPeople === "") return null;

            return (
              <ListItem
                key={person.id}
                name={person.firstname + " " + person.lastname}
                onClick={() => {
                  person.faculty = 0;

                  setProject((prevProject) => ({
                    ...prevProject,
                    people: prevProject.people.concat(person),
                  }));
                }}
                backgroundColor="#f0f0f0"
                isAdd
              />
            );
          })}
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            {project.people.map((person) => (
              <ListItem
                key={person.id}
                name={`${person.firstname} ${person.lastname}`}
                onClick={() => {
                  setProject((prevProject) => ({
                    ...prevProject,
                    people: prevProject.people.filter(
                      (item) => item.id !== person.id
                    ),
                  }));
                }}
                edit={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1rem",
                        margin: 0,
                        marginLeft: "20px",
                      }}
                    >
                      Position:
                    </p>
                    <FormInput
                      type="text"
                      placeholder={0}
                      value={person.position}
                      onChange={(e) => {
                        setProject((prevProject) => ({
                          ...prevProject,
                          people: prevProject.people.map((item) =>
                            item.id === person.id
                              ? { ...item, position: e.target.value }
                              : item
                          ),
                        }));
                      }}
                      required
                    />
                    <p
                      style={{
                        fontSize: "1rem",
                        margin: 0,
                        marginLeft: "20px",
                      }}
                    >
                      Faculty:
                    </p>
                    <InputGroup.Checkbox
                      aria-label="Checkbox for Faculty"
                      onChange={(e) => {
                        setProject((prevProject) => ({
                          ...prevProject,
                          people: prevProject.people.map((item) =>
                            item.id === person.id
                              ? { ...item, faculty: e.target.checked }
                              : item
                          ),
                        }));
                      }}
                      value={person.faculty}
                      checked={person.faculty}
                    />
                  </div>
                }
                isDelete
              />
            ))}
          </div>

          <h2>Publications</h2>
          <FormInput
            name="Search for a publication"
            type="text"
            placeholder="Search for a publication"
            label="searchPublications"
            value={searchPublications}
            onChange={(e) => setSearchPublications(e.target.value)}
          />

          {filteredPublications.map((publication) => {
            if (searchPublications === "") return null;

            return (
              <ListItem
                key={publication.id}
                name={publication.title}
                onClick={() => {
                  setProject((prevProject) => ({
                    ...prevProject,
                    publications: prevProject.publications.concat(publication),
                  }));
                }}
                backgroundColor="#f0f0f0"
                isAdd
              />
            );
          })}
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            {project.publications.map((publication) => (
              <ListItem
                key={publication.id}
                name={publication.title}
                onClick={() => {
                  setProject((prevProject) => ({
                    ...prevProject,
                    publications: prevProject.publications.filter(
                      (item) => item.id !== publication.id
                    ),
                  }));
                }}
                isDelete
              />
            ))}
          </div>
        </Container>

        <Button variant="primary" type="submit">
          Submit
        </Button>

        {projectId && projectId !== "new" ? (
          <Button
            variant="danger"
            onClick={() => setShow(true)}
            style={{ left: "60px", position: "relative" }}
          >
            Delete project
          </Button>
        ) : null}
      </Form>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this project? After you delete this
          project, it will not be recoverable.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteProject(projectId)}>
            Delete project
          </Button>
          <Button variant="primary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditProject;
