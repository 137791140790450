import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import SearchBar from "../../components/SearchBar";
import NavbarCustom from "../../components/Navbar";
import { Button } from "react-bootstrap";

const PublicationsList = () => {
  const [publications, setPublications] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const publicationFilter = useMemo(() => {
    const list = publications.filter((publication) => {
      if (publication.title.toLowerCase().includes(search.toLowerCase()))
        return publication;
    });

    if (list.length === 0)
      return (
        <tr>
          <td colSpan="3">No publications found</td>
        </tr>
      );

    const renderedList =
      list.length &&
      list.map((publication) => (
        <tr
          key={publication.id}
          onClick={() => {
            navigate(`/admin/papers/${publication.id}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <td>{publication.title}</td>
          <td>{publication.year}</td>
          <td>{publication.pubtype || "Not Published"}</td>
        </tr>
      ));

    return renderedList;
  }, [search]);

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await fetch("/api/publications", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPublications(data);
        } else {
          throw new Error("Failed to fetch publications");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPublications();
  }, []);

  return (
    <div>
      <header>
        <NavbarCustom isAdmin />
      </header>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <h1>Publications List</h1>
        <Button
          onClick={() => {
            navigate("/admin/papers/new");
          }}
        >
          Add Publication
        </Button>
      </div>
      <SearchBar
        label="Search Publications"
        placeholder="Search by title"
        value={search}
        onChange={handleChange}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Year</th>
            <th>Publication Type</th>
          </tr>
        </thead>
        <tbody>
          {search.length > 0
            ? publicationFilter
            : publications.map((publication) => (
                <tr
                  key={publication.id}
                  onClick={() => {
                    navigate(`/admin/papers/${publication.id}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td>{publication.title}</td>
                  <td>{publication.year}</td>
                  <td>{publication.pubtype || "Not Published"}</td>
                </tr>
              ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PublicationsList;
