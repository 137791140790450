import React from "react";
import { XCircleFill, PlusCircleFill } from "react-bootstrap-icons";

/**
 * ListItem component represents a single item in a list.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.key - The unique key of the item.
 * @param {string} props.name - The name of the item.
 * @param {function} props.onClick - The click event handler for the item.
 * @param {boolean} props.isAdd - Indicates whether the item has an "add" button.
 * @param {boolean} props.isDelete - Indicates whether the item has a "delete" button.
 * @returns {JSX.Element} The rendered ListItem component.
 */
const ListItem = ({
  key,
  name,
  onClick,
  backgroundColor,
  isAdd,
  isDelete,
  edit,
}) => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1rem",
      borderBottom: "1px solid #ccc",
      borderTop: "1px solid #ccc",
      backgroundColor: backgroundColor,
    },
    title: {
      fontSize: "1.2rem",
      margin: 0,
    },
  };

  return (
    <div style={styles.container} id={key}>
      <div>
        <p style={styles.title}>{name}</p>
      </div>
      <div>{edit}</div>
      <div>
        {isAdd && <PlusCircleFill onClick={onClick} />}
        {isDelete && <XCircleFill onClick={onClick} />}
      </div>
    </div>
  );
};

export default ListItem;
