import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import FormInput from "../../components/FormInput";
import NavbarCustom from "../../components/Navbar";
import { FormLabel } from "react-bootstrap";
import ListItem from "../../components/ListItem";

const EditPaper = () => {
  const { paperId } = useParams();
  const navigate = useNavigate();

  const [paper, setPaper] = useState({
    title: "",
    year: "",
    pubtype_id: "",
    pubType: "",
    citation: "",
    abstract: "",
    doiurl: "",
    pdf: "",
    projects: [],
    people: [],
  });

  const [file, setFile] = useState(null);
  const [paperPubTypes, setPaperPubTypes] = useState([]);
  const [show, setShow] = useState(false);

  //Users
  const [people, setPeople] = useState([]);
  const [searchPeople, setSearchPeople] = useState("");

  //Projects
  const [projects, setProjects] = useState([]);
  const [searchProject, setSearchProject] = useState("");

  const filteredPeople = useMemo(() => {
    const search = people.filter(
      (person) =>
        person.firstname.toLowerCase().includes(searchPeople.toLowerCase()) ||
        person.lastname.toLowerCase().includes(searchPeople.toLowerCase())
    );

    if (!paper.people) return search;

    const noDuplicate = search.filter(
      (person) => !paper.people.some((item) => item.id === person.id)
    );

    return noDuplicate;
  }, [people, searchPeople, paper]);

  const filteredProjects = useMemo(() => {
    const search = projects.filter((project) =>
      project.name.toLowerCase().includes(searchProject.toLowerCase())
    );

    if (!paper.projects) return search;

    const noDuplicate = search.filter(
      (project) =>
        paper.projects.findIndex((item) => item.id === project.id) === -1
    );

    return noDuplicate;
  }, [projects, searchProject, paper]);

  useEffect(() => {
    const fetchPaper = async () => {
      try {
        const response = await fetch(`/api/publications/${paperId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPaper(() => ({
            ...data.publication[0],
            people: data.users || [],
            projects: data.project || [],
          }));
        } else {
          navigate("/admin/papers");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPubType = async () => {
      try {
        const response = await fetch(`/api/publications/pubTypes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPaperPubTypes(data);

          if (paperId === "new") {
            setPaper((prevPaper) => ({
              ...prevPaper,
              pubtype_id: data[0].id,
            }));
          }
        } else {
          throw new Error("Failed to fetch groups");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPeople = async () => {
      try {
        const response = await fetch(`/api/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPeople(data);
        } else {
          throw new Error("Failed to get people");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await fetch(`/api/projects`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          throw new Error("Failed to fetch projects");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (paperId && paperId !== "new") {
      fetchPaper();
    }

    fetchPeople();
    fetchProjects();
    fetchPubType();
  }, [paperId]);

  const deletePaper = async (id) => {
    try {
      const response = await fetch(`/api/publications/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        navigate("/admin/papers");
      } else {
        throw new Error("Failed to delete paper");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "pdf") setFile(files[0]);

    setPaper((prevPaper) => ({
      ...prevPaper,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("pdf", file ? file : paper.pdf);
      formData.append("title", paper.title);
      formData.append("year", paper.year);
      formData.append("pubtype_id", paper.pubtype_id);
      formData.append("citation", paper.citation);
      formData.append("abstract", paper.abstract);
      formData.append("doiurl", paper.doiurl);
      formData.append("people", JSON.stringify(paper.people));
      formData.append("projects", JSON.stringify(paper.projects));

      const isPaperId = paperId && paperId !== "new";

      const response = await fetch(
        isPaperId ? `/api/publications/${paperId}` : `/api/publications`,
        {
          method: isPaperId ? "PUT" : "POST",
          body: formData,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        navigate("/admin/papers");
      } else {
        throw new Error("Failed to update paper");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <header>
        <NavbarCustom isAdmin />
      </header>
      <h1>Edit paper</h1>
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Container>
          <Row>
            <Col>
              <FormInput
                name="Title"
                type="text"
                placeholder="Title"
                label="title"
                value={paper.title}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col>
              <FormInput
                name="Year"
                type="text"
                placeholder="Year"
                label="year"
                value={paper.year}
                onChange={handleInputChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Publication Type</Form.Label>
              <Form.Select
                value={paper.pubtype_id}
                onChange={(e) => {
                  setPaper((prevPaper) => ({
                    ...prevPaper,
                    pubtype_id: e.target.value,
                  }));
                }}
              >
                {paperPubTypes.map((group) => (
                  <option value={group.id}>{group.name}</option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <FormInput
                name="Citation"
                type="text"
                placeholder="Citation"
                label="citation"
                value={paper.citation}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormLabel>Abstract</FormLabel>
              <Form.Control
                as="textarea"
                aria-label="Abstract"
                id="abstract"
                style={{
                  marginBottom: "20px",
                }}
                onChange={(e) => {
                  setPaper((prevPaper) => ({
                    ...prevPaper,
                    abstract: e.target.value,
                  }));
                }}
                value={paper.abstract}
              />
            </Col>
            <Col>
              <FormInput
                name="DOI URL"
                type="text"
                placeholder="DOI URL"
                label="doiurl"
                value={paper.doiurl}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {paper.pdf ? (
                <div>
                  <FormLabel>PDF</FormLabel>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="red"
                    class="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => {
                      setPaper((prevPaper) => ({
                        ...prevPaper,
                        pdf: "",
                      }));
                    }}
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                  </svg>
                </div>
              ) : (
                <FormInput
                  name="PDF"
                  type="file"
                  label="pdf"
                  accept=".pdf"
                  value={paper.pdf}
                  onChange={handleInputChange}
                />
              )}
            </Col>
          </Row>

          <h2>People</h2>
          <FormInput
            name="Search"
            type="text"
            placeholder="Search"
            label="searchPeople"
            value={searchPeople}
            onChange={(e) => setSearchPeople(e.target.value)}
          />
          {filteredPeople.map((person) => {
            if (searchPeople === "") return null;

            return (
              <ListItem
                key={person.id}
                name={person.firstname + " " + person.lastname}
                onClick={() => {
                  person.faculty = 0;

                  setPaper((prevPaper) => ({
                    ...prevPaper,
                    people: prevPaper.people.concat(person),
                  }));
                }}
                backgroundColor="#f0f0f0"
                isAdd
              />
            );
          })}

          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            {paper.people.map((person) => (
              <ListItem
                key={person.id}
                name={`${person.firstname} ${person.lastname}`}
                onClick={() => {
                  setPaper((prevPaper) => ({
                    ...prevPaper,
                    people: prevPaper.people.filter(
                      (item) => item.id !== person.id
                    ),
                  }));
                }}
                edit={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "1rem",
                        margin: 0,
                        marginLeft: "20px",
                      }}
                    >
                      Position:
                    </p>
                    <FormInput
                      type="text"
                      placeholder={0}
                      value={person.position}
                      onChange={(e) => {
                        setPaper((prevPaper) => ({
                          ...prevPaper,
                          people: prevPaper.people.map((item) =>
                            item.id === person.id
                              ? { ...item, position: e.target.value }
                              : item
                          ),
                        }));
                      }}
                      required
                    />
                  </div>
                }
                isDelete
              />
            ))}
          </div>

          <h2>Projects</h2>
          <FormInput
            name="Search"
            type="text"
            placeholder="Search"
            label="searchProject"
            value={searchProject}
            onChange={(e) => setSearchProject(e.target.value)}
          />
          {filteredProjects.map((project) => {
            if (searchProject === "") return null;

            return (
              <ListItem
                key={project.id}
                name={project.name}
                onClick={() => {
                  setPaper((prevPaper) => ({
                    ...prevPaper,
                    projects: prevPaper.projects.concat(project),
                  }));
                }}
                backgroundColor="#f0f0f0"
                isAdd
              />
            );
          })}

          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            {paper.projects
              ? paper.projects.map((project) => (
                  <ListItem
                    key={project.id}
                    name={project.name}
                    onClick={() => {
                      setPaper((prevPaper) => ({
                        ...prevPaper,
                        projects: prevPaper.projects.filter(
                          (item) => item.id !== project.id
                        ),
                      }));
                    }}
                    isDelete
                  />
                ))
              : null}
          </div>
        </Container>

        <Button variant="primary" type="submit">
          Submit
        </Button>

        {paperId && paperId !== "new" ? (
          <Button
            variant="danger"
            onClick={() => setShow(true)}
            style={{ left: "60px", position: "relative" }}
          >
            Delete paper
          </Button>
        ) : null}
      </Form>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete paper</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this paper? After you delete this
          paper, it will not be recoverable.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deletePaper(paperId)}>
            Delete paper
          </Button>
          <Button variant="primary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditPaper;
