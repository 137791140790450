import { createTheme } from "@mui/material/styles";

let theme = createTheme();

theme = createTheme({
  palette: {
    primary: {
      main: "#CCBA1B",
    },
    secondary: {
      main: "#EC5323",
    },
    error: {
      main: "#f44336",
    },
    background: {
      default: "#FCFAF7",
    },
    accent: {
      main: "#F27E59",
    },
    white: {
      main: "#FCFAF7",
    },
    typography: {
      fontFamily: "ReplicaPro",
      h4: {
        fontSize: "39px",
      },
    },
  },
});

export default theme;
