import React from "react";
import Form from "react-bootstrap/Form";

const SearchBar = (props) => {
  const { onChange, placeholder, label, className } = props;

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        className={className}
      />
    </>
  );
};
export default SearchBar;
