import React from "react";
import { Link } from "react-router-dom";
/**
 * Creates a project component.
 *
 * @param {Object} props - The props object.
 * @param {Object} props.project - The project object.
 * @param {string} props.project.shortname - The shortname of the project.
 * @param {string} props.project.photo - The photo of the project.
 * @returns {React.Component} The project component.
 */

function ProjectPastCard(props) {
  const { id, shortname, photo } = props.project;

  return (
    <Link to={`/projects/${id}`}>
      <div className="past-project-card">
        <div
          style={{
            width: "225px",
            height: "225px",
            borderRadius: "29px",
            backgroundImage: `url(${photo || "/domo_kun.jpg"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <h3
          style={{
            color: "#707070",
            fontFamily: "swiss-normal",
            fontSize: "23px",
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          {shortname}
        </h3>
      </div>
    </Link>
  );
}

export default ProjectPastCard;
